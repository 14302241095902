export const WORLD_CUP = {
  sportId: '4',
  competitionId: '11729982',
  eventId: '28569726',
  routeHash: `4:11729982:28569726`,
  // blockedRunners: [KINGS_XI_PUNJAB_RUNNER_ID]
};
export const FIFA_CUP = {
  sportId: '1',
  competitionId: '12469077',
  eventId: '31345701',
  routeHash: `1:12469077:31345701`,
};
export const BBL_WOMENS = {
  sportId: '4',
  competitionId: '10529093',
  eventId: '28045743',
  routeHash: `4:10529093:28045743`,
  // blockedRunners: [KINGS_XI_PUNJAB_RUNNER_ID]
};
export const BBL_CUP = {
  sportId: '4',
  competitionId: '10328858',
  eventId: '27996014',
  routeHash: `4:10328858:27996014`,
  // blockedRunners: [KINGS_XI_PUNJAB_RUNNER_ID]
};

export const IPL_CUP = {
  sportId: '4',
  competitionId: '101480',
  eventId: '28127348',
  routeHash: `4:101480:28127348`,
  // blockedRunners: [KINGS_XI_PUNJAB_RUNNER_ID]
};
